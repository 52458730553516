<template>
    <div class="lesson-video-container">
        <!-- <vue-plyr>
            <video ref="videoPlayer" @timeupdate="updateTime" @pause="onPause" :src="video_url" controls crossorigin playsinline autoplay>
                <source size="1080" :src="video_url" type="video/mp4" />
            </video>
        </vue-plyr> -->
        <DashVideo ref="videoPlayer" @video-timetracking="handleVideoTimeTracking($event)" :src="video_url" :isPrivate="true" crossorigin />
        <div class="vid-actionBtn">
            <div class="back-btn mb-4">
                <a @click="$router.go(-1)" >
                    <span class="backIcon">
                        <span class="icon-arrow"></span>
                    </span>
                    Back
                </a>
            </div>
            <div class="d-flex align-items-center">
                <!-- <div class="vidNav-btn me-3">
                    <button class="univ-btn prev-btn me-2 py-2 px-3"> <i class="icon-arrow"></i> Prev</button>
                    <button class="univ-btn py-2 px-3">Next <i class="icon-arrow"></i></button>
                </div> -->
                <div class="vidcourse-list">
                    <span class="listBtn" v-b-tooltip.hover title="Curriculum Lists" @click="toggleSidebar">
                        <i class="icon-list"></i>
                    </span>
                </div>
            </div>
        </div>
        <div :class="{ 'showcartSidebar': isSidebarVisible, 'cartSidebar': true }">
            <div class="cartTitle">
                <h2>Course content</h2>
                <div @click="toggleSidebar" class="closeIcon">
                    <span class="icon-close"></span>
                </div>
            </div>
            <div class="vid-coursList">
                <template v-if="curriculums.length > 0"> 
                    <div class="accordion">
                        <b-card no-body class="mb-1" v-for="(curriculum, i) in curriculums" :key="i" >
                            <b-card-header header-tag="header">
                                <b-button block v-b-toggle="'accordion-'+i" class="d-block w-100" variant="info"> 
                                    <span> {{ curriculum.title }} </span>
                                    <span class="icon-down"></span>
                                </b-button>
                            </b-card-header>
                            <b-collapse :id="'accordion-'+i" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <ul>
                                        <li v-for="(lesson, index) in curriculum.lessons" :key="index" v-bind:class = "(lesson.lesson_id == lessonId)?'active':''"> 
                                            <span class = "d-block w-100" > {{ lesson.title }} </span>
                                            <router-link :to="{name: 'LessonVideos', params: { course: courseId, curriculum: curriculum.curriculum_id, lesson: lesson.lesson_id } }" class="playBtn-course" >
                                                <span class="icon-video"></span> Play
                                            </router-link>
                                        </li>
                                    </ul>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import store from "@/store"
import api from '@/services/api'
import DashVideo from '../../components/DashVideo.vue'
export default {
    name:"LessonVideos",
    components: {
        DashVideo
    },
    data() {
        return {
            isSidebarVisible: false,
            userId: store.getters.user.id,
            courseId: this.$route.params.course,
            curriculumId: this.$route.params.curriculum,
            lessonId: this.$route.params.lesson,
            video_url:null,
            currentTime: 0,
            curriculums: [],
        };        
    },
    mounted(){
        let app = this;
        app.renderDetail();
        app.courseBreakdown();
    },
    methods: {
        toggleSidebar() {
            this.isSidebarVisible = !this.isSidebarVisible;
        },
        async courseBreakdown(){
            let app = this;
            await axios.get(api.getUrl("/course/test-four/" + app.courseId)).then(function (response) {
                if(response.data.success) {
                    app.curriculums = response.data.data.course_curriculums;
                }
            });
        },
        async renderDetail() {
            let app = this;
            const response = await axios.post(api.getUrl('/request/video'), {userId:app.userId, courseId:app.courseId, lessonId:app.lessonId, curriculumId:app.curriculumId }, {
                // responseType: 'blob',
                // headers: {
                //     'Content-Type': 'application/json',
                //     'Range': 'bytes=0-999999'
                // },
            });
            //const blob = new Blob([response.data], { type: 'video/mp4' });
            //app.video_url = URL.createObjectURL(blob);
            app.video_url = response.data.url;
        },
        updateTime() {
            let app = this;
            // Access the video element using the $refs object
            const videoPlayer = app.$refs.videoPlayer;
            // Update the currentTime data property
            app.currentTime = app.secondsToHms(parseInt(videoPlayer.currentTime));
        },
        onPause() {
            let app = this;
            // The video has been paused, update the time in the database
            app.updateUserVideoPlayingStatus();
        },
        secondsToHms(d) {
            d = Number(d);
            let h = Math.floor(d / 3600);
            let m = Math.floor(d % 3600 / 60);
            let s = Math.floor(d % 3600 % 60);

            let hDisplay = h > 0 ? h +':': "00:";
            let mDisplay = m > 0 ? m + ':' : "00:";
            let sDisplay = s > 0 ? s : "00";
            return hDisplay + mDisplay + sDisplay; 
        },
        async updateUserVideoPlayingStatus() {
            let app = this;
            axios.post(api.getUrl('/request/video/timetracking'),{userId:app.userId, courseId:app.courseId, lessonId:app.lessonId, curriculumId:app.curriculumId,currentTime:app.currentTime }).then((response) => {
                console.log(JSON.stringify(response.data));
            });
        },
        handleVideoTimeTracking($event){
            let app = this;
            app.currentTime = $event;
            app.updateUserVideoPlayingStatus();
        },
    },
    beforeDestroy() {
        let app = this;
        // The component is about to be destroyed, update the time in the database
        app.updateUserVideoPlayingStatus();
    },
    created() {
        let app = this;
        app.$watch(() => app.$route.query,() => {
            app.courseId = app.$route.params.course;
            app.curriculumId = app.$route.params.curriculum;
            app.lessonId = app.$route.params.lesson;
            app.renderDetail();
            app.courseBreakdown();
        })
    },
}
</script>